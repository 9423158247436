// input {
//     outline:none;
// }
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;600&display=swap');

.nav-buttons {
    display: flex;
    align-items: center;
    gap: 20px;
}

.contact-us-sidebar {
    // text-decoration: none;
    // font-family: "sora";
    // color: white;
    // cursor: pointer !important;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.9rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: #fff;
    cursor: pointer;

    &:hover {
        color: #1B83C2;
        transition: 0.2s ease-in-out;
    }
}

.contact-us {
    text-decoration: none;
    font-family: "sora";
    color: white;
    cursor: pointer !important;

    @media screen and (max-width: 768px){
        display: none;
    }

}

.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    max-width: 800px;
    padding-top: 100px;

    @media screen and (max-width: 846px){
        padding-top: 80px;
        width: 80%;
    }
    @media screen and (max-width: 426px){
        padding-top: 50px;
        width: 100%;
    }
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: white;
    padding: 60px 0px 60px 0px;
    width: 100%;
    border-radius: 25px;
    border: #254d8d solid 2px;

    @media screen and (max-width: 846px){
        gap: 10px;
    }

    @media screen and (max-width: 426px){
        padding: 30px 0 10px 0;
        gap: 10px;
    }
}

.text-field-wrapper {
    width: 70%;
    align-items: center;
    justify-content: center;
}

.text-field-row {
    display: flex;
    gap: 10px;
    width: 70%;

    @media screen and (max-width: 846px){
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

}

.text-field {
    padding: 10px;
    font-size: 15px;
    font-family: 'Sora';
    width: 100%;
    border-radius: 8px;
    border: 1px grey solid;

    @media screen and (max-width: 846px){
        font-size: 12px;
    }

    @media screen and (max-width: 426px){
        font-size: 10px;
    }
}

.select-field {
    font-size: 15px;
    font-family: 'Sora';
    width: 100%;

    @media screen and (max-width: 846px){
        font-size: 12px;
    }

    @media screen and (max-width: 426px){
        font-size: 10px;
    }
}

.text-area {
    padding: 10px;
    font-size: 15px;
    font-family: 'Sora';
    height: 200px;
    width: 100%;
    border-radius: 8px;
    border: 1px grey solid;
    resize: none;

    @media screen and (max-width: 846px){
        font-size: 12px;
    }

    @media screen and (max-width: 426px){
        font-size: 10px;
        height: 100px;
    }
}

.form-title {
    width: 70%;
    font-family: 'Sora';
    font-weight: bold;
    color: #1f4e9a;
    font-size: 40px;

    @media screen and (max-width: 846px){
        font-size: 30px;
    }

    @media screen and (max-width: 426px){
        font-size: 20px;
    }
    
}

.form-subtitle {
    width: 70%;
    font-size: 14px;
    font-family: 'Sora';
    // color: white;
    color: rgb(60, 60, 60);
    padding-bottom: 5px;

    @media screen and (max-width: 846px){
        font-size: 12px;
    }
    @media screen and (max-width: 426px){
        font-size: 10px;
    }

}

.label-text {
    padding-bottom: 5px;
    color: grey;
    font-family: 'Sora';

    @media screen and (max-width: 846px){
        font-size: 14px;
    }

    @media screen and (max-width: 426px){
        font-size: 10px;
    }
}

.submit-button {
    width: 30%;
    height: 40px;
    background-color: #254d8d;
    color: white;
    font-family: 'Sora';
    font-size: 15px;
    border: #254d8d solid 1px;
    border-radius: 10px;

    @media screen and (max-width: 846px){
        font-size: 14px;
    }

    @media screen and (max-width: 426px){
        font-size: 10px;
    }
}

.error-text {
    color: red;
    font-size: 13px;
    padding: 5px;
}

.error-styling {
    background-color: rgb(255, 209, 209);
    border: 2px red solid;
}

.gradient-background {
    background: linear-gradient(100deg, #27a4db, #254d8d, #2b73a0);
    background-size: 600% 600%;

    -webkit-animation: AnimationName 8s ease infinite;
    -moz-animation: AnimationName 8s ease infinite;
    animation: AnimationName 8s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 48%}
    50%{background-position:100% 53%}
    100%{background-position:0% 48%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 48%}
    50%{background-position:100% 53%}
    100%{background-position:0% 48%}
}
@keyframes AnimationName {
    0%{background-position:0% 48%}
    50%{background-position:100% 53%}
    100%{background-position:0% 48%}
}

.overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background: white;
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 200px;
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
  }
  
  .modal-content h2 {
    margin-top: 20px;
    color: #333;
  }